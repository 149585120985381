<div class="app mat-typography" [dir]="dir"
     [ngClass]="{'app-dark': dark, 'boxed': boxed, 'collapsed-sidebar': collapseSidebar,
      'compact-sidebar': compactSidebar, 'side-panel-opened': sidePanelOpened }">
  <mat-toolbar *ngIf="TEST_MODE" class="main-header production-band">
    <div class="production-band-content">
      <h1>Plateforme de test</h1>
    </div>
  </mat-toolbar>
  <mat-toolbar class="main-header" [style.background-color]="platformConfig.platform.callcenterAppColor">
    <button (click)="sidemenu.toggle()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <div class="branding">
      <img [src]="platformConfig.platform.logo" alt="logo-platforme">
    </div>
    <div class="right-top-menu">
      <button appToggleFullscreen mat-icon-button>
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button [matMenuTriggerFor]="user" mat-icon-button class="ml-xs">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #user="matMenu" x-position="before">
        <a [routerLink]="['/', 'profile', 'edition']">
          <button mat-menu-item>
            <mat-icon>account_box</mat-icon>
            Profile
          </button>
        </a>
        <button (click)="onSignOutUser()" mat-menu-item>
          <mat-icon>exit_to_app</mat-icon>
          {{'Sign Out' | translate}}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container [ngClass]="{'app-inner-second': TEST_MODE}" class="app-inner ">
    <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (open)="sidePanelOpened = true"
    (close)="sidePanelOpened = false" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">
      <mat-nav-list appAccordion class="navigation" (click)="updatePS()">
        <div class="user-profile-photo">
          <img *ngIf="profile.picture === null" src="assets/images/user-bg.png" alt="photo">
          <img *ngIf="profile.picture !== null" [src]="mediaBaseUrl + profile.picture.path" alt="profile picture">
        </div>
          <mat-list-item  appAccordionLink *ngFor="let menuitem of menuItemsList" group="{{menuitem.state}}">
            <a appAccordionToggle class="relative" [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span>{{ menuitem.name | translate }}</span>
              <span fxFlex></span>
              <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            </a>
            <a appAccordionToggle class="relative" href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span>{{ menuitem.name | translate }}</span>
              <span fxFlex></span>
              <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            </a>
            <a appAccordionToggle class="relative" href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span>{{ menuitem.name | translate }}</span>
              <span fxFlex></span>
              <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            </a>
            <a appAccordionToggle class="relative" href="javascript:;" *ngIf="menuitem.type === 'sub'">
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span>{{ menuitem.name | translate }}</span>
              <span fxFlex></span>
              <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
              <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
            </a>
            <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
              <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
                <a [routerLink]="['/', menuitem.state, childitem.state ]" class="relative">{{ childitem.name | translate }}</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-list-item>
          <div class="partner-logo">
            <div [style.background-color]="platformConfig.platform.callcenterAppColor" class="partner-logo-title">
              <h1>{{platformConfig.platform.name}}</h1>
            </div>
            <div class="partner-logo-container">
              <img [src]="platformConfig.platform.logo" alt="partner-logo"> 
              <img src="../../../assets/images/uba-logo.png" alt="uba_logo" style="height: 50px; width: 50px; object-fit: contain;">
            </div>
          </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <mat-tab-group [selectedIndex]="1" mat-stretch-tabs>
        <mat-tab>
          <ng-template matTabLabel>Today</ng-template>
          <div class="scroll">
            <mat-list class="pt-1 pb-1">
              <mat-list-item>
                <div fxLayout="column">
                  <h1 class="ma-0">{{ today | date:'EEEE' }}</h1>
                  <h3 class="mat-text-muted ma-0"><span>{{ today | date:'dd' }}</span>&nbsp;<span>{{ today | date:'MMMM' }}</span></h3>
                </div>
              </mat-list-item>
            </mat-list>
            <mat-nav-list>
              <mat-divider></mat-divider>
              <h3 mat-subheader class="text-uppercase font-weight-bold">Stocks</h3>
              <mat-list-item>
                 <a matLine href="javascript:;">NASDAQ</a>
                 <span class="mat-text-muted text-md mr-xs ml-xs">4,492.87</span>
                 <span>-0.29%</span>
              </mat-list-item>
              <mat-list-item>
                 <a matLine href="javascript:;">NYSE</a>
                 <span class="mat-text-muted text-md mr-xs ml-xs">10,692.07</span>
                 <span>-0.53%</span>
              </mat-list-item>
              <mat-list-item>
                 <a matLine href=javascript:;>DOW J</a>
                 <span class="mat-text-muted text-md mr-xs ml-xs">17,046.81</span>
                 <span>-0.14%</span>
              </mat-list-item>
              <mat-list-item>
                 <a matLine href="javascript:;">APPL</a>
                 <span class="mat-text-muted text-md mr-xs ml-xs">100,89</span>
                 <span>+0.75%</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader class="text-uppercase font-weight-bold">Weather</h3>
              <mat-list-item>
                 <a matLine href="javascript:;">{{ today | date:'shortTime' }}</a>
                 <p matLine class="mat-text-muted">London</p>
                 <span class="mat-h1 mr-1">
                   <span class="pe-is-w-blizzard"></span>
                 </span>
                 <span class="mat-h1">26°</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader class="text-uppercase font-weight-bold">Todo</h3>
              <mat-list-item>
                <a matLine href="javascript:;">Learn Angular 2.0</a>
                <p matLine class="mat-text-muted text-md">2:45PM</p>
              </mat-list-item>
              <mat-list-item>
                 <a matLine href="javascript:;">Learn Angular Material</a>
                 <p matLine class="mat-text-muted text-md">3:20PM</p>
              </mat-list-item>
              <mat-list-item>
                 <a matLine href="javascript:;">Write documentation</a>
                 <p matLine class="mat-text-muted text-md">6:00PM</p>
              </mat-list-item>
              <mat-divider class="mt-xs mb-xs"></mat-divider>
              <h3 mat-subheader class="text-uppercase font-weight-bold">Stats</h3>
              <mat-list-item>
                <p class="text-md" matLine>Local Storage (4023 / 10690)</p>
                <mat-progress-bar  matLine mode="determinate" color="warn" value="40"></mat-progress-bar>
              </mat-list-item>
              <mat-list-item>
                <p class="text-md" matLine>Cloud Storage (700 / 1030)</p>
                <mat-progress-bar  matLine mode="determinate" color="accent" value="70"></mat-progress-bar>
              </mat-list-item>
              <mat-list-item>
                <p class="text-md" matLine>Local Storage (20 / 100)</p>
                <mat-progress-bar  matLine mode="determinate" value="20"></mat-progress-bar>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>Notifications</ng-template>
          <div class="scroll">
            <mat-nav-list>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-text-primary">people</mat-icon>
                <h4 matLine>Social</h4>
                <p matLine>Ligula Purus Adipiscing</p>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-text-warn">local_offer</mat-icon>
                <h4 matLine>Promotions</h4>
                <p matLine>Etiam Ligula Dapibus</p>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-text-accent">info</mat-icon>
                <h4 matLine>Updates</h4>
                <p matLine>Sollicitudin Euismod Fringilla</p>
              </mat-list-item>

              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-indigo">delete_sweep</mat-icon>
                <h4 matLine>Removed 6 items from task list</h4>
                <span class="text-md mat-text-muted" matLine>{{ 1427207139000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>check_circle</mat-icon>
                <h4 matLine>Completed 2 projects</h4>
                <span class="text-md mat-text-muted" matLine>{{ 1427412725000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>notifications_paused</mat-icon>
                <h4 matLine>Muted notifications</h4>
                <span class="text-md mat-text-muted" matLine>{{ 1427546580000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>person_add</mat-icon>
                <h4 matLine>Added Joel to contact list</h4>
                <span class="text-md mat-text-muted" matLine>{{ 1428275520000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>phone_missed</mat-icon>
                <h4 matLine>Missed live call from Ellie</h4>
                <span class="text-md mat-text-muted" matLine>{{ 1428830580000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>group_add</mat-icon>
                <h4 matLine>You've been added to HR group</h4>
                <span class="text-md mat-text-muted" matLine>{{ 1429363920000 | date: 'fullDate' }}</span>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-sidenav>
    <router-outlet></router-outlet>
  </mat-sidenav-container>
</div>
