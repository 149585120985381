<div style="display: flex; justify-content:center;flex-direction: column;">
 <div>
  <h1 mat-dialog-title style="padding: 15px;">Confirmation</h1>
  <div mat-dialog-content style="padding-left: 15px;">
    <p>{{message}}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{ 'NO' | translate}}</button>
    <button mat-button (click)="onYesClick()" cdkFocusInitial>{{'YES' | translate}}</button>
  </div>
 </div>
</div>