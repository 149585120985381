<div class="delete-confirmation-content">
  <div class="delete-advise-text">
    <h1><mat-icon>error-outline</mat-icon> {{ 'WARNING' | translate }} </h1>
  </div>
  <div *ngIf="firstStep" class="delete-confirmation-step-content">
    <h4>
      {{'You have requested to delete the element ' | translate}} {{dataType | translate }} : <span>{{dataName | translate}}</span>
    </h4>
    <div class="checkbox-confirmation">
      <mat-checkbox [(ngModel)]="clickToConfirm">{{'Checked to confirm the deleting ' | translate}}</mat-checkbox>
      <p *ngIf="clickToConfirmError">
        {{'You must confirm the deleting' | translate}}
      </p>
    </div>
    <div class="delete-confirmation-button-content">
      <button (click)="cancel()" mat-raised-button color="primary">
        {{'NO' | translate}}
      </button>
      <button (click)="confirm()" mat-raised-button color="warn">
        {{'YES' | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="secondStep" class="delete-confirmation-step-content">
    <h5>
      {{'Confirm this element deleting ' | translate}} {{dataType | translate }} : <span>{{dataName | translate}}</span>
    </h5>
    <div class="delete-confirmation-button-content">
       <button (click)="cancel()" mat-raised-button color="primary">
           {{'CANCEL' | translate}}
       </button>
       <button (click)="confirmDelete()" mat-raised-button color="warn">
           {{'DELETE' | translate}}
       </button>
      </div>
  </div>

</div>

