<div class="photo-container" >
  <img [src]="mediaUrl" alt="photo" *ngIf="mediaUrl !== null" class="organization-defaut-photo">
  <img src="../../../../assets/images/image.png" alt="photo" *ngIf="mediaUrl === null" 
  class="organization-image">

 
  <div class="organization-buttons-content" >
    <div class="organization-buttons" *appHasPermission="'CLK-MEDIA-POST'">
      <input style="display: none;" (change)="onChangeMedia($event)" type="file" accept=".jpg, .jpeg, .png" #media>
      <button mat-raised-button [style.background-color]="platformConfig.platform.callcenterAppColor" type="submit" (click)="media.click()" class="edit-button">{{  "EDIT" | translate}}</button>
      <button *ngIf="photoFormControl.value !== null" mat-raised-button color="red" type="submit" (click)="onDeleteMedia()" class="delete-button">{{  "DELETE" | translate}}</button>
    </div>
  </div>
  <div class="spinner-content" *ngIf="mediaLoading">
    <mat-progress-spinner mode="indeterminate" [diameter]="55" [strokeWidth]="5"></mat-progress-spinner>
  </div>
</div>
