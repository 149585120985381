<div>
  <button mat-icon-button
          mat-button-sm color="danger"
          class="mr-1"
          matTooltip="{{ 'CLOSE' | translate }}"
          (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  &nbsp;
  {{snackMessage.message | translate}}
</div>
