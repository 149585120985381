<div class="error-dialog-content" *ngIf="error.message !== undefined">
  <span>ERROR</span><br>
  <ul>
    <li>
      <span>
        {{error.message | translate}}
      </span>
    </li>
  </ul>
</div>

<div class="error-dialog-content" *ngIf="isFormError()">
  <span>ERRORS</span> <br>
  <ul>
    <li *ngFor="let message of getMessages()">
      {{ message.key }}
      <ul *ngIf="isValueObject(message.value)">
        <li *ngFor="let messageChild of message.value">
          {{messageChild.value}}
        </li>
      </ul>
      <span *ngIf="!isValueObject(message.value)">
        {{message.key}} : {{message.value}}
      </span>
    </li>
  </ul>
</div>

<div class="error-dialog-content" *ngIf="requestDebugMode">
  <span>Debug Messages</span>
  <ul>
    <li *ngFor="let message of getDebugMessages()">
      <span *ngIf="isValueObject(message.value)">{{ message.key }}</span>
      <ul *ngIf="isValueObject(message.value)">
        <li *ngFor="let messageChild of message.value">
          {{message.key}}: {{messageChild.value}}
        </li>
      </ul>
      <span *ngIf="!isValueObject(message.value)">
        {{message.key}} : {{message.value}}
      </span>
    </li>
  </ul>
</div>
