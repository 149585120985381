<mat-card>
  <mat-card-content class="page-title">
    {{ 'PROFILE' | translate }}
  </mat-card-content>
</mat-card>
<div class="profile-page-content">
  <div  fxLayout="row"  fxLayoutWrap="wrap"  fxLayoutAlign="center start">
    <div fxFlex.gt-sm="55" fxFlex="50">
      <mat-card class="mat-card-top">
        <mat-tab-group mat-stretch-tabs>
          <mat-tab>
            <ng-template matTabLabel>{{'General' | translate}}</ng-template>
            <mat-card-content>
              <div class="profile-form-content">
                <form fxLayout="column" [formGroup]="form" (submit)="profileEdit()">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'Fisrtname' | translate }}" [formControl]="form.controls['firstname']" >
                      <mat-error *ngIf=" form.controls['firstname'].touched && !form.controls['firstname'].valid"
                                 class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'Lastname' | translate}}" [formControl]="form.controls['lastname']">
                      <mat-error  *ngIf=" form.controls['lastname'].touched && !form.controls['lastname'].valid"
                                  class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'Address' | translate }}" [formControl]="form.controls['address']" (keyup)="onTap($event)">
                      <mat-error *ngIf="form.controls['address'].touched && !form.controls['address'].valid "
                                 class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'Phone' | translate }}" [formControl]="form.controls['phone']">
                      <mat-error *ngIf=" form.controls['phone'].touched && !form.controls['phone'].valid"
                                 class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'Username' | translate }}" [formControl]="form.controls['username']" readonly>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input (keyup)="onTap($event)" matInput placeholder="{{ 'Email' | translate }}" [formControl]="form.controls['email']">
                      <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                      <mat-error *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" class="mat-text-warn">
                        {{'INVALID EMAIL' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input (keyup)="onTap($event)" type="number" matInput placeholder="{{ 'TimezoneOffset' | translate}}" [formControl]="form.controls['timezoneOffset']">
                      <mat-error  *ngIf="form.controls['timezoneOffset'].touched && form.controls['timezoneOffset'].hasError('required')"
                                  class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <button mat-button mat-raised-button type="reset" color="accent">{{'Cancel' | translate }}</button>
                  <button mat-button mat-raised-button type="submit" [disabled]="!form.valid || !inputRequiredChange || profileLoading" color="primary" class="shadow-none">
                    {{'Save' | translate}}
                    <mat-progress-spinner *ngIf="profileLoading" mode="indeterminate" class="warn loading-circle"
                                          [diameter]="25" [strokeWidth]="2">
                    </mat-progress-spinner>
                  </button>
                </form>
              </div>
              <div class="profile-photo-content">
                <app-photo-block [photoFormControl]="form.controls['picture']"
                                 (onMediaSelected)="onPictureSelected($event)">
                </app-photo-block>
              </div>
            </mat-card-content>
          </mat-tab>
          <mat-tab>
            <ng-template matTabLabel>{{'Security' | translate}}</ng-template>
            <mat-card-content>
              <div class="profile-security">
                <form fxLayout="column" [formGroup]="formPwd" (submit)="passwordEdit()">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{'Current password' | translate}}" type="password" [formControl]="formPwd.controls['oldPassword']">
                      <mat-error *ngIf="formPwd.controls['oldPassword'].touched &&
                       formPwd.controls['oldPassword'].hasError('required')" class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{'New password' | translate}}" type="password" [formControl]="formPwd.controls['plainPasswordFirst']">
                      <mat-error *ngIf=" formPwd.controls['plainPasswordFirst'].touched &&
                       formPwd.controls['plainPasswordFirst'].hasError('required')" class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}
                      </mat-error>
                      <mat-error *ngIf=" formPwd.controls['plainPasswordFirst'].touched &&
                       formPwd.controls['plainPasswordFirst'].hasError('minlength')"
                        class="mat-text-warn">
                        {{ 'PASSWORD MUST AT LEAST 6 CARACTERS' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field>
                      <input matInput placeholder="{{'Confirm new password' | translate}}" type="password" [formControl]="formPwd.controls['plainPasswordSecond']">
                      <mat-error *ngIf=" formPwd.controls['plainPasswordSecond'].touched &&
                       formPwd.controls['plainPasswordSecond'].hasError('required')" class="mat-text-warn">
                        {{ 'REQUIRED' | translate }}

                      </mat-error>
                      <mat-error *ngIf=" formPwd.controls['plainPasswordSecond'].touched &&
                       formPwd.controls['plainPasswordSecond'].hasError('passwordMismatch')" class="mat-text-warn">
                        {{ 'PASSWORD MISMATCH' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <button mat-button mat-raised-button type="reset" color="accent">{{'Cancel' | translate }}</button>
                  <button mat-button mat-raised-button type="submit" [disabled]="!formPwd.valid || pwdLoading" class="shadow-none" color="primary">
                    {{'Save' | translate}}
                    <mat-progress-spinner *ngIf="pwdLoading" mode="indeterminate" class="warn loading-circle"
                                          [diameter]="25" [strokeWidth]="2">
                    </mat-progress-spinner>
                  </button>
                </form>
              </div>
            </mat-card-content>
          </mat-tab>
        </mat-tab-group>
        <hr>
      </mat-card>
    </div>
  </div>

</div>
