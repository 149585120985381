<div class="delete-confirmation-content">
  <div class="delete-advise-text">
    <h1><mat-icon>error-outline</mat-icon> {{ 'WARNING' | translate }} </h1>
  </div>
  <div class="delete-confirmation-step-content">
    <h4 *ngIf="action === 'freeze'" >
      {{'You are about to freeze the operation' | translate}}: {{ operation }} 
      <!-- : 
      {{ 'in the account ' | translate }} <span>{{reference}}</span> -->
    </h4>
    <h4 *ngIf="action === 'unfreeze'" >
      {{'You are about to unfreeze the operation' | translate}}:  {{ operation }}
    </h4>
    <div class="checkbox-confirmation">
      <p>
        {{'You confirm the operation ?' | translate}}
      </p>
    </div>
    <div class="delete-confirmation-button-content">
      <button (click)="cancel()" mat-raised-button color="primary">
        {{'NO' | translate}}
      </button>
      <button (click)="confirm()" mat-raised-button color="warn">
        {{'YES' | translate}}
      </button>
    </div>
  </div>
</div>

