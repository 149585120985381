<div class="user-log-dialog-content">
  <h4> {{ "ACTIVITY DETAILS" | translate }}</h4>
  <form>
    <div *ngFor="let log of logDetails">
      <div  fxLayout="row" fxLayoutAlign="start center" class="mb-2">
        <mat-input-container style="width: 100%" >
          <textarea  *ngIf="isNotAnNumber(getObjectValue(log))" matInput
           placeholder="{{ getObjectKey(log) | translate }}"
              readonly value="{{ getObjectValue(log) | translate }}" > </textarea>
          <textarea  *ngIf="!isNotAnNumber(getObjectValue(log))" matInput placeholder="{{ getObjectKey(log) | translate }}"
              readonly value="{{ getObjectValue(log) }}" ></textarea>      
        </mat-input-container>
      </div>
    </div>
    <button mat-raised-button color="accent" type="reset" (click)="dialogRef.close(null)">
      {{ "CLOSE" | translate }}
    </button>
  </form>
</div>
