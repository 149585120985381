<mat-card-content class="password-change-popup">
  <mat-card-title class="password-change-title-popup" color="primary">
    <h1>{{ 'YOUR PASSWORD HAS EXPIRED YOU MUST CHANGE IT' | translate }}</h1>
  </mat-card-title>

  <div class="password-change-error-msg">

    <div *ngIf="message !== null && message !== ''">
      <h2 style="color: green;">{{ message | translate }}</h2>
    </div>

    <div *ngIf="errorMsg !== null && errorMsg !== ''">
      <h2>{{ errorMsg | translate }}</h2>
    </div>

  </div>

  <div class="password-change-content">
    <div *ngIf="!passwordChanged">
      <form fxLayout="column" [formGroup]="form" (submit)="onSubmit()">
        <div class="password-change-item">
          <mat-form-field>
            <input matInput placeholder="{{'Current password' | translate}}" type="password" [formControl]="form.controls['oldPassword']">
            <mat-error *ngIf="form.controls['oldPassword'].touched && !form.controls['oldPassword'].valid" class="mat-text-warn">
              {{ 'REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="password-change-item">
          <mat-form-field>
            <input matInput placeholder="{{'New password' | translate}}" type="password" [formControl]="form.controls['plainPasswordFirst']">
            <mat-error *ngIf=" form.controls['plainPasswordFirst'].touched && form.controls['plainPasswordFirst'].hasError('required')" class="mat-text-warn">
              {{ 'REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf=" form.controls['plainPasswordFirst'].touched && form.controls['plainPasswordFirst'].hasError('pattern')" class="mat-text-warn">
              {{ 'PASSWORD MUST AT LEAST 8 CARACTER, A UPPERCASE, A NUMBER AND A SPECIAL CHARACTER' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="password-change-item">
          <mat-form-field>
            <input matInput placeholder="{{'Confirm new password' | translate}}" type="password"
                   [formControl]="form.controls['plainPasswordSecond']">
            <mat-error *ngIf=" form.controls['plainPasswordSecond'].touched && form.controls['plainPasswordSecond'].hasError('required')" class="mat-text-warn">
              {{ 'REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf=" form.controls['plainPasswordSecond'].touched && form.controls['plainPasswordSecond'].hasError('passwordMismatch')" class="mat-text-warn">
              {{ 'PASSWORD MISMATCH' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <button type="submit" mat-raised-button [style.background-color]="platformConfig.platform.callcenterAppColor" [disabled]="!form.valid || loading" class="password-change-button">
          {{'Save' | translate}}
          <mat-progress-spinner *ngIf="loading" mode="indeterminate" class="warn loading-circle"
                                [diameter]="25" [strokeWidth]="2">
          </mat-progress-spinner>
        </button>
      </form>
    </div>
    <div *ngIf="passwordChanged">
      <div class="close-password-change-popup">
        <button type="submit" mat-raised-button [style.background-color]="platformConfig.platform.callcenterAppColor" class="password-change-close-button" (click)="dialogRef.close(null)">
          {{ 'CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</mat-card-content>
